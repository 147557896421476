import { format } from 'date-fns';

export function isEventPast(eventDate: Date): boolean {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize to midnight
  return eventDate < today;
}

export function formatDate(date: Date): string {
  return format(date, 'MMMM d, yyyy');
}
