import React from 'react';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import { allEvents, Event } from '../data/eventsData';
import { formatDate, isEventPast } from '../utils/eventsUtils';

const Events: React.FC = () => {
  const upcomingEvents = allEvents
    .filter((event) => !isEventPast(event.date))
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  const pastEvents = allEvents
    .filter((event) => isEventPast(event.date))
    .sort((a, b) => b.date.getTime() - a.date.getTime());

  const renderEventCard = (event: Event, eventPast: boolean) => (
    <Grid item xs={12} sm={6} md={4} key={event.id}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          position: 'relative',
        }}
      >
        {eventPast && (
          <Box
            sx={{
              bgcolor: 'rgba(0, 0, 0, 0.6)',
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: '400',
              left: 0,
              p: 1,
              position: 'absolute',
              textAlign: 'center',
              top: 0,
              width: '100%',
              zIndex: 1,
            }}
          >
            Event Ended
          </Box>
        )}
        <CardActionArea
          href={event.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <CardMedia
            component="img"
            image={event.imageUrl}
            alt={event.title}
            sx={{
              objectFit: 'cover',
            }}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {event.title}
            </Typography>

            <Typography variant="body1" color="text.primary">
              {event.description}
            </Typography>

            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {formatDate(event.date)} {event.otherInfo}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );

  return (
    <Container maxWidth="lg" sx={{ mb: 4, mt: 4 }}>
      {upcomingEvents.length > 0 && (
        <>
          <Typography variant="h4" component="h1" gutterBottom>
            Upcoming Events
          </Typography>
          <Grid container spacing={4}>
            {upcomingEvents.map((event) => renderEventCard(event, false))}
          </Grid>
        </>
      )}

      <Box sx={{ mb: 4 }} />

      {pastEvents.length > 0 && (
        <>
          <Typography variant="h4" component="h1" gutterBottom>
            Past Events
          </Typography>
          <Grid container spacing={4}>
            {pastEvents.map((event) => renderEventCard(event, true))}
          </Grid>
        </>
      )}
    </Container>
  );
};

export default Events;
